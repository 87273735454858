import React, {useState} from 'react';
import { BsTwitter, BsInstagram, BsLinkedin} from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaGithub} from 'react-icons/fa';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
const [formData, setFormData] = useState({name:'',email:'',message:''});
const [isFormSubmitted, setIsFormSubmitted] = useState(false);
const [loading, setLoading] = useState(false);

const {username, email, message}=formData;

const handleChangeInput = (e)=>{
  const {name, value} = e.target;
  setFormData({...formData, [name]: value});
};

const handleSubmit =()=>{
  setLoading(true);

  const contact ={
    _type:'contact',
    name: username,
    email: email,
    message: message,
  };

  client.create(contact)
    .then(()=>{
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch((err)=>console.log(err));
};


  return (
    <>
      <h2 className='head-text'>Take a coffe & chat with me </h2>
      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.email} alt="email" />
          <a href="mailto:mario@karajallo.com" className='p-text'>mario@karajallo.com</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.mobile} alt="phone" />
          <a href="tel:+595985743672" className='p-text'>+595 985 743672</a>
        </div>
      </div>

    {!isFormSubmitted ?(
    <div className='app__footer-form app__flex'>
        <div className='app__flex'>
          <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
        </div>
      <div className='app__flex'>
        <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
      </div>
      <div>
        <textarea 
          className='p-text'
          placeholder="Your Message"
          value={message}
          name="message"
          onChange={handleChangeInput}
        />  
      </div>

      <button type='button' className='p-text' onClick={handleSubmit}>{!loading ? 'Send Message': 'Sending...'}</button>
    </div>
    ): (
      <div>
        <h3 className='head-text'>
          Thank you for getting in touch
        </h3>
      </div>

    )}
      <div className='app__social-footer'target='_blank' rel='noreferrer'>
        <div>
          <a href="https://github.com/mariokarajallo">
            <FaGithub/>
          </a>

        </div> 
        <div>
          <a href="https://www.linkedin.com/in/mariokarajallo/" target='_blank' rel='noreferrer'>
            <FaLinkedinIn/>
          </a>
            

        </div> 
        <div>
          <a href="https://twitter.com/Mariokarajallo" target='_blank' rel='noreferrer'>
            <BsTwitter/>
          </a>
        </div> 
        <div>
          <a href="https://www.instagram.com/mariokarajallo" target='_blank' rel='noreferrer'>
            <BsInstagram/>
          </a>
        </div> 
    </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__primarybg'
)